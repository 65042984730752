<template>
  <v-container fluid>
    <v-row v-if="product" class="pb-0 pb-sm-7">
      <v-col sm="6" cols="12" order="1" order-sm="1" class="pb-0 pa-sm-3">
        <v-card flat v-if="selectedImage" class="image-preview-wrapper">
          <ProductPromo
            v-if="warehousePromoBody"
            :warehousePromo="product.warehousePromo"
          />
          <ProductImages
            :images="product.media"
            :name="product.name"
            :key="product.productId"
            :highlight="highlight"
            :heartKey="heartKey"
          />
        </v-card>
      </v-col>

      <v-col sm="6" cols="12" order="2" order-sm="2" class=" pt-0 pa-sm-3">
        <div class="product">
          <h1 itemprop="name" class="product-name mb-4 mt-4 mt-sm-0">
            {{ product.name }}
          </h1>
          <v-layout no-gutters class="mb-1 mt-2">
            <div itemprop="descr" class="product-descr">
              <span v-if="product.description">
                {{ product.description }}
              </span>
            </div>
            <div
              class="weight-unit ml-2"
              v-if="isPezzo && product.priceUmDisplay"
            >
              {{ $n(product.priceUmDisplay, "currency") }}/{{
                product.weightUnitDisplay
              }}
            </div>
          </v-layout>
          <div class="product-brand">
            {{ product.shortDescr }}
          </div>
          <div
            class="product-descr"
            v-if="
              product.metaData &&
                product.metaData.product_info &&
                product.metaData.product_info.weight_description
            "
          >
            {{ product.metaData.product_info.weight_description }}
          </div>

          <div class="product-info-wrapper">
            <!-- surgelato -->
            <v-tooltip
              left
              v-if="product.metaData.product_info.IS_SURGELATO == true"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="product-info mr-4" v-bind="attrs" v-on="on">
                  <img
                    aria-hidden
                    alt="Immagine surgelato"
                    src="/product-info/surgelato.svg"
                  />
                </div>
              </template>
              <span>Surgelato</span>
            </v-tooltip>
          </div>

          <v-row
            no-gutters
            justify="space-between"
            align="center"
            class="pb-3 mt-3"
          >
            <v-col cols="6">
              <ProductPrice
                :product="product"
                :showDescrWeight="false"
              ></ProductPrice>
            </v-col>
            <v-col cols="6" class="actions">
              <div
                class="d-flex align-center justify-center pa-2 "
                v-for="selectOption in selectOptionsArray"
                :key="selectOption.name"
                @click.stop.prevent
                @mousedown.stop
              >
                <v-select
                  dense
                  :hide-details="true"
                  outlined
                  v-model="selectedOptions[selectOption.parameterName]"
                  :items="selectOption.options"
                  :label="selectOption.name"
                  item-text="key"
                  item-value="value"
                  @change="optionChanged"
                />
              </div>

              <template v-if="product.available > 0">
                <div class="qty-wrapper">
                  <ProductQty
                    :selectedOptions="selectedOptions"
                    :product="product"
                    :item="item"
                    :showAddToCartLabel="false"
                  />
                </div>
              </template>
              <div v-else class="not-available">
                Il prodotto non è disponibile
              </div>
            </v-col>
          </v-row>

          <v-divider />
          <v-row no-gutters align="center" justify="start" class="mt-3 mt-sm-0">
            <v-col cols="1" sm="12">
              <v-tooltip
                left
                :key="heartKey"
                nudge-bottom="10"
                content-class="right-arrow"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click.stop.prevent="addToFavorites"
                    @mousedown.stop
                    class="heart"
                  >
                    <v-icon color="secondary" v-if="highlight">
                      $heartfull
                    </v-icon>
                    <v-icon v-else color="secondary">$heart</v-icon>
                  </v-btn>
                </template>
                <span class="red-text">{{ $t("product.addToFavorites") }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="11"
              v-if="$vuetify.breakpoint.xs"
              class="text-caption pl-2"
            >
              {{ $t("product.addToFavorites") }}
            </v-col>
            <span
              class="red--text"
              v-if="product.productInfos.STATE_P == 'true'"
            >
              Il prodotto è a quantità limitata, potrebbe non essere disponibile
              al momento del ritiro.
            </span>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.product {
  align-items: left;
  .product-name {
    font-size: 28px;
    font-weight: 600;
    color: #333;
    margin: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: visible;
    line-height: 28px;
  }
  .red--text {
    color: red;
  }
  .product-brand {
    color: var(--v-grey-darken2);
    font-size: 18px;
    padding-bottom: 0;
  }
  .product-descr {
    span {
      font-weight: 400;
      font-size: 16px;
      color: $text-color;
      border: 1px solid var(--v-grey-darken1);
      border-radius: $border-radius-root;
      white-space: nowrap;
      overflow: hidden;
      padding: 1px 5px;
    }
  }
  .weight-unit {
    color: $text-color;
    font-size: 16px;
  }
  .actions {
    width: 100%;
    max-width: 250px;
    .qty-wrapper {
      display: flex;
      align-items: center;
      justify-content: left;
      .qty-wrap {
        background-color: transparent;
        border-radius: 0 !important;
        height: 50px;
        width: auto;
        max-width: 250px;
        -ms-flex-positive: 1;
        flex-grow: 1;
        .v-btn {
          height: 50px;
          max-width: 250px;
        }
        .val-cnt {
          flex-grow: 4;
        }
        :deep(.minus),
        :deep(.plus) {
          border-radius: $border-radius-root !important;
        }
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          justify-content: flex-end;
        }
      }
      .item-total {
        font-weight: bold;
        font-size: 16px;
        flex-grow: 1;
        text-align: right;
        margin-left: 12px;
        @media #{map-get($display-breakpoints, 'xs-only')} {
          min-width: 90px;
          flex-grow: 0;
          font-size: 13px;
        }
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .add-to-cart {
    width: auto;
  }
}
:deep(.v-tooltip__content) {
  background: var(--v-primary-lighten2);
  color: $white;
  text-align: center;
  line-height: 1.5;
  max-width: 180px;
  font-weight: 600;
  padding: 2px 5px;
  // &.right-arrow {
  //   overflow: inherit !important; // that's important to make the pseudo-element visible outside the main container
  //   z-index: 20;

  //   &::before {
  //     content: "";
  //     position: absolute;
  //     width: 12px !important;
  //     height: 4px !important;
  //     top: 10px;
  //     bottom: 0;
  //     right: -12px;
  //     z-index: 21;
  //     background: var(--v-primary-base);
  //     // custom appearance rules
  //   }
  // }
}
::v-deep .cur-price {
  font-size: 34px;
}

.promo-box {
  position: absolute;
  margin: 10px;
  z-index: 1;
}
</style>

<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductImages from "@/components/product/ProductImages.vue";
import ProductPromo from "./ProductPromo.vue";
import ProductQty from "./ProductQty.vue";
import productMixin from "~/mixins/product";

import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";

export default {
  name: "ProductDetail",
  props: ["selectedImage", "product"],
  components: {
    ProductPrice,
    ProductImages,
    ProductQty,
    ProductPromo
  },
  mixins: [productMixin],
  directives: {
    mask: mask
  },
  data() {
    return {
      imageDetailDialog: false,
      selectOptionsArray: [],
      selectedOptions: {}
    };
  },
  created() {
    this.setOptionsArray();
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    item() {
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    isPezzo: function() {
      return this.product.productInfos.TIPOLOGIA == "Pezzo";
    },
    warehousePromoBody() {
      return get(this.product, "warehousePromo.view.body", null);
    }
  },
  methods: {
    setOptionsArray() {
      if (this.product.selectOptions) {
        this.selectOptionsArray = cloneDeep(this.product.selectOptions);
      }
    },
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    }
  },
  watch: {
    product(val, oldVal) {
      if (val.productId !== oldVal.productId) {
        this.setOptionsArray();
      }
    }
  }
};
</script>
